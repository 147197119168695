.pointer{
    cursor: pointer;
}

.badge-soft-warning {
    color: #FFC107;
    background-color: rgba(255, 193, 7, 0.18);
}

.badge-soft-info {
    color: #3452e1;
    background-color: rgba(52, 82, 225, 0.18);
}

#button-download-as-xls {
    color: #000 !important;
    background-color: #fff !important;
    border: none !important;
    padding: 0 !important;
}

.goog-te-gadget {
    z-index: 999;
    position: absolute;
    left: 133em;
    top: 1em;
}

.goog-te-gadget .goog-te-gadget-simple .goog-te-gadget-icon{
    display: none;
}