.contain-bg {
    background-color: #2a3042 ;
    color: #fff;
}

.page-content-frontend {
    padding: 20px 0px 0px 0px;
}

.card-height-frontend {
    height: 900px;
    overflow-y: auto;
}

.location-search-input{
    padding: 8px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px;
}

.location-search-input:focus-visible {
    outline: none !important;
}

.location-search-input:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2) !important;
}

