// start react-datepicker

input.react-datepicker-ignore-onclickoutside {
    border: 1px solid #b7b9c1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    height: 36px;
    font-size: 12px;
}

#inline-date .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
    background: #fff;
    background-image: url("../../../images/calendar.png");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: right 3% top 50%;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.form-control {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.form-control:focus{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2) !important;
}
#inline-date .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding-left: 15px;
    height: 36px;
    font-size: 12px;
    cursor: pointer;
}
#inline-date .react-datepicker__close-icon {
    right: 35px !important;
    border-right: 1px solid #b7b9c1 !important;
}
.react-datepicker__close-icon::after {
    background-color: #808080 !important;
    display: block !important;
}

.react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
    width: 100%;
    border: 1px solid #b7b9c1;

    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 15px;
    height: 36px;
    font-size: 12px;
}

// end react-datepicker