// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.onbording
{
    margin: 4px -2px;
    background: #2a3042;
    border-radius: 8px;
}
.account-pages
{
    background: #2a3042;
    height: 100vh;
}
.household-tabs
{
    display: flow-root;
    text-align: center;
    width: 100%;
    background: white;
    padding: 12px 0px;
}
.household-tabs .nav-link
{
    padding: 0.5rem 3rem;
}
.houshold-body
{
    background: #f3f3f7;
    padding: 0;
}
.nav-tabs-custom .household-tabs  .nav-item
{
    display: inline-block;
}
.verti-timeline .event-list
{
    padding: 0px 0px 5px 30px!important;
}
#activitytext div span p{
    margin-bottom: 0.2rem;
}
.fix-height
{
    height: 382px;
    overflow-y: auto;
}
.edit
{
    display: none;
}
.houshold-body  .card-body:hover .edit
{
    display: block;
}
.benifit-recive .verti-timeline .event-list .event-timeline-dot
{
    left: -13px;
}
.profile-img
{
    height: 10.5rem;
    width: 10.5rem;
}
.profile-card
{
    background-color: rgb(212, 219, 249);
    box-shadow: none;
    margin-bottom: 0px;
}
.profile-card .custom-switch
{
    padding: 35px 0px 0px 0px; 
}
.fz-32
{
    font-size: 32px!important;
}
.fw-6
{font-weight: 600;}
.modal-dialog
{
    margin: 0 auto;
}
.modal-body
{
    text-align: center;
}
.verti-timeline .event-list .event-timeline-dot
{
    font-size: 20px!important;
}
.modal-body
{
    text-align: initial!important;
}
.verti-timeline
{
    border-left: none!important;
}
.navbar-brand-box
{
    padding: 0 0.5rem 0px 13px;
}
.MuiTableCell-head
{
    font-weight: 600!important;
}
// .sb-avatar__text
// {
//     position: absolute;
//     margin-left: -11px;
// }